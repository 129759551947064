<template>
    <div class="coupons">
        <h1 class="text-secondary font">Moji kuponi</h1>
        
        <div class="mt-2">
            <b-row>
                <b-col cols="12" md="7">
                    <b-row class="p-2 mx-0" align-v="center" style="background-color: #E6E7E6;">
                        <b-col cols="3" md="3" xl="2">
                            <h1 class="mb-0">
                                <fa class="text-secondary" :rotation="90" icon="tag" size="2x"/>
                            </h1>
                        </b-col>
                        <b-col cols="9" align="left">
                            <h1 class="d-inline mb-0">Imate </h1>
                            <h1 class="d-inline mb-0 text-secondary font">2 kupona</h1>
                        </b-col>
                    </b-row>
                    <b-row class="p-2 mx-0" align-v="center" style="background-color: #189DD4;">
                        <b-col cols="5">
                            <h4 class="mb-0 text-white font">ZBRANE TOČKE</h4>
                        </b-col>
                        <b-col cols="6" offset="1" class="pr-0" align="left">
                            <h4 class="mb-0 text-white font">700 točk</h4>
                            <h5 class="mb-0 text-white" style="opacity: 75%;">(Še 200 točk do novega kupona)</h5>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </div>

        <h1 class="mt-4 text-secondary font">Več o točkah in kuponih</h1>
        <div>
            <b-card  no-body class="mt-1 mb-0 board">
                <b-card-header style="background-color: #E6E7E6;" header-tag="header" class=" p-0" role="tab">
                    <a class="w-100" v-b-toggle :href="'#category-1'" @click.prevent variant="grey">
                        <b-row class="w-100 mx-0">
                            <b-col cols="10" md="11" class="py-1 d-flex flex-column justify-content-around">
                                <h3 class="mb-0 font">Kako zbiram točke?</h3>
                            </b-col>
                            <b-col cols="2" md="1" class="d-flex flex-column justify-content-around" align="right">
                                <h2 class="mb-0 text-secondary"><FeatherIcon icon="ChevronDownIcon" size="1x"/></h2>
                            </b-col>
                        </b-row>
                    </a>
                </b-card-header>
                <b-collapse :id="'category-1'" accordion="my-accordion" role="tabpanel" style="background-color: #E6E7E6; margin-top: 0.3rem;">
                    <b-card-body>
                        <b-row align-v="center">
                            <b-col align="center">
                                <h4 class="mb-0 text-secondary font">Redno plačevanje razdelilnikov v roku</h4>
                                <h4 class="mb-0 mt-1 font">50 točk</h4>
                            </b-col>
                            <b-col align="center">
                                <h4 class="mb-0 text-secondary font">Prijava na e-račun</h4>
                                <h4 class="mb-0 mt-1 font">200 točk</h4>
                            </b-col>
                            <b-col align="center">
                                <h4 class="mb-0 text-secondary font">Nakup v spletni trgovini</h4>
                                <h4 class="mb-0 mt-1 font">50 točk</h4>
                            </b-col>
                            <b-col align="center">
                                <h4 class="mb-0 text-secondary font">Oddaja oglasa v e-soseski</h4>
                                <h4 class="mb-0 mt-1 font">50 točk</h4>
                            </b-col>
                            <b-col align="center">
                                <h4 class="mb-0 text-secondary font">Prva registracija v e-sosesko</h4>
                                <h4 class="mb-0 mt-1 font">100 točk</h4>
                            </b-col>
                            <b-col align="center">
                                <h4 class="mb-0 text-secondary font">Enkratno dodeljene točke v posameznih akcijah</h4>
                                <h4 class="mb-0 mt-1 font">100 točk</h4>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-collapse>
            </b-card>
        </div>

        <div>
            <b-card no-body class="mt-1 mb-0 board">
                <b-card-header style="background-color: #E6E7E6;" header-tag="header" class=" p-0" role="tab">
                    <a class="w-100" v-b-toggle :href="'#category-2'" @click.prevent variant="grey">
                        <b-row class="w-100 mx-0">
                            <b-col cols="10" md="11" class="py-1 d-flex flex-column justify-content-around">
                                <h3 class="mb-0 font">Kako kahko unovčim zbrane točke?</h3>
                            </b-col>
                            <b-col cols="2" md="1" class="d-flex flex-column justify-content-around" align="right">
                                <h2 class="mb-0 text-secondary"><FeatherIcon icon="ChevronDownIcon" size="1x"/></h2>
                            </b-col>
                        </b-row>
                    </a>
                </b-card-header>
                <b-collapse :id="'category-2'" accordion="my-accordion" role="tabpanel" style="background-color: #E6E7E6; margin-top: 0.3rem;">
                    <b-card-body>
                        <h4>Ko zberete skupno <span class="text-secondary font">300 točk</span>, se te pretvorijo v <span class="text-secondary font">en kupon</span>, ki ga lahko izkoristite za cenejši nakup izdelkov v posebnih mesečnih akcijah.
                            Če zberete <span class="text-secondary font">2 kupona</span>, jidva lahko izkoristite za <span class="text-secondary font">darilo presenečenja</span>.
                            Preverite trenutno ponudbo izdelkov za katere lahko unovčite kupone <b-link class="text-secondary font" to="#">tukaj</b-link>.</h4>
                    </b-card-body>
                </b-collapse>
            </b-card>
        </div>

        <div class="p-5">
            <swiper :swiper-data="swiperData" :pagination="false" type="sale"/>
        </div>
    </div>
</template>

<script>

    import Swiper from '@/views/Components/Swiper'
    import {BRow, BCol, BCard, BCardHeader, BCollapse, BCardBody, BLink, VBToggle} from 'bootstrap-vue'
    import Vue from 'vue'

    export default {
        components: {
            Swiper,
            BRow,
            BCol,
            BCard,
            BCardHeader,
            BCollapse,
            BCardBody,
            BLink
        },
        data() {
            return {
                swiperData: [
                    { img: 'https://s3.eu-central-1.amazonaws.com/cnj-img/images/TB/TB4P8dU8aB0v', title: 'GORENJE', model: 'pralni stroj', price: 299.99, ribbon: 'UNOVČI KUPON' },
                    { img: 'https://s3.eu-central-1.amazonaws.com/cnj-img/images/TB/TB4P8dU8aB0v', title: 'GORENJE', model: 'pralni stroj', price: 299.99, ribbon: 'UNOVČI KUPON'},
                    { img: 'https://s3.eu-central-1.amazonaws.com/cnj-img/images/TB/TB4P8dU8aB0v', title: 'GORENJE', model: 'pralni stroj', price: 299.99, ribbon: 'UNOVČI KUPON' },
                    { img: 'https://s3.eu-central-1.amazonaws.com/cnj-img/images/TB/TB4P8dU8aB0v', title: 'GORENJE', model: 'pralni stroj', price: 299.99, ribbon: 'UNOVČI KUPON'},
                    { img: 'https://s3.eu-central-1.amazonaws.com/cnj-img/images/TB/TB4P8dU8aB0v', title: 'GORENJE', model: 'pralni stroj', price: 299.99, ribbon: 'UNOVČI KUPON' }
                ]
            }
        },
        methods: {

        },
        created() {
            Vue.directive('b-toggle', VBToggle)
        }

    }
</script>

<style>
.coupons .font {
  font-weight: bold;
}
.coupons .board, .coupons .card-header{
  box-shadow: none;
  border: none;
  border-radius: 0 !important;
}
</style>
